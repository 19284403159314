import React, { useRef, useEffect } from "react"
import * as THREE from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { WEBGL } from "three/examples/jsm/WebGL"
import gsap from "gsap"
import { Link } from "gatsby"

import SEO from "../components/seo"
import useCalcHeight from "../components/hooks/useCalcHeight"

const Error = () => {
  const containerRef = useRef()
  const sceneRef = useRef()
  const cameraRef = useRef()
  const rendererRef = useRef()
  const controlsRef = useRef()
  const modelRef = useRef()

  useCalcHeight()

  useEffect(() => {
    const container = containerRef.current

    const initScene = () => {
      sceneRef.current = new THREE.Scene()
    }

    const initCamera = () => {
      const { fov, aspectRatio, near, far } = {
        fov: 45,
        aspectRatio: container.clientWidth / container.clientHeight,
        near: 0.01,
        far: 180,
      }

      cameraRef.current = new THREE.PerspectiveCamera(
        fov,
        aspectRatio,
        near,
        far
      )

      const { x, y, z } = { x: 0, y: 0, z: 48 }

      cameraRef.current.position.set(x, y, z)
    }

    const initLight = () => {
      const ambLight = new THREE.AmbientLight()
      sceneRef.current.add(ambLight)
    }

    const initRenderer = () => {
      const antiAlias = window.devicePixelRatio > 1 ? false : true

      rendererRef.current = new THREE.WebGLRenderer({
        antialias: antiAlias,
        powerPreference: "high-performance",
        alpha: true,
      })

      rendererRef.current.physicallyCorrectLights = true
      rendererRef.current.outputEncoding = THREE.sRGBEncoding
      rendererRef.current.toneMapping = THREE.ReinhardToneMapping

      rendererRef.current.setPixelRatio(Math.min(window.devicePixelRatio || 2))
      rendererRef.current.setClearColor(0xffffff, 0)
      rendererRef.current.setSize(container.clientWidth, container.clientHeight)

      container.appendChild(rendererRef.current.domElement)
    }

    const initControls = () => {
      controlsRef.current = new OrbitControls(
        cameraRef.current,
        rendererRef.current.domElement
      )

      controlsRef.current.autoRotate = true
      controlsRef.current.autoRotateSpeed = 0.32

      controlsRef.current.enableRotate = false
      controlsRef.current.enableZoom = false
      controlsRef.current.enableKeys = false
      controlsRef.current.enablePan = false
    }

    const initModel = () => {
      const loader = new GLTFLoader()
      const dracoLoader = new DRACOLoader()

      dracoLoader.setDecoderPath("/draco/")
      loader.setDRACOLoader(dracoLoader)

      loader.load(
        `/models/compressed.glb`,
        gltf => {
          const objectAmount = gltf.scene.children[0].children.length

          // show only one randomized object in the model
          const randomiseModel = (min, max) => min + Math.random() * (max - min)
          const randomModel = randomiseModel(0, objectAmount - 1).toFixed(0)

          modelRef.current = gltf.scene.children[0].children[randomModel]
          modelRef.current.geometry.center()
          modelRef.current.material.wireframe = true // object in wireframe mode

          controlsRef.current.target = new THREE.Vector3(
            modelRef.current.position.x,
            modelRef.current.position.y,
            modelRef.current.position.z
          )

          const box = new THREE.Box3().setFromObject(modelRef.current)
          cameraRef.current.position.z = box.getSize().x

          sceneRef.current.add(modelRef.current)

          gsap.from(containerRef.current, {
            autoAlpha: 0,
            duration: 1.8,
            ease: "power2.out",
          })
        },
        () =>
          gsap.to("body", { autoAlpha: 1, duration: 0.35, ease: "power1.out" }),
        error => {
          alert("Failed to load 3D experience. Please try another browser.")
          console.log(error)
        }
      )
    }

    const init = () => {
      initScene()
      initCamera()
      initLight()
      initRenderer()
      initControls()
      initModel()
    }

    const onWindowResize = () => {
      cameraRef.current.aspect = container.clientWidth / container.clientHeight
      cameraRef.current.updateProjectionMatrix()

      rendererRef.current.setPixelRatio(Math.min(window.devicePixelRatio || 2))
      rendererRef.current.setSize(container.clientWidth, container.clientHeight)
    }

    window.addEventListener("resize", onWindowResize, false)

    let frameID

    const animate = () => {
      controlsRef.current.update()
      rendererRef.current.render(sceneRef.current, cameraRef.current)
      frameID = requestAnimationFrame(animate)
    }

    if (WEBGL.isWebGLAvailable()) {
      init()
      animate()
    } else {
      const warning = WEBGL.getWebGLErrorMessage()
      alert(
        `This website does not support your browser. Please update. ${warning}`
      )
    }

    return () => {
      if (WEBGL.isWebGLAvailable()) {
        window.removeEventListener("resize", onWindowResize, false)
        cancelAnimationFrame(frameID)
      }
    }
  }, [])

  return (
    <main className="error">
      <SEO
        title="Page Not Found"
        description="The page you’re looking for can’t be found."
      />
      <h1 className="error-header non-interactive">404</h1>
      <Link to="/" className="error-link">
        Go Home
      </Link>
      <div style={{ opacity: 0.22 }} id="scene" ref={containerRef} />
    </main>
  )
}

export default Error
