import { useLayoutEffect } from "react"
import { throttle } from "lodash"

// calculate "real" browser innerHeight
// (excluding bottom UI bar in mobile...
// ... devices, e.g. iOS Safari), and set CSS height variable
const useCalcHeight = () => {
  const calcHeight = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  const throttleCalcHeight = throttle(calcHeight, 500)

  useLayoutEffect(() => {
    calcHeight()
    window.addEventListener("resize", throttleCalcHeight)
    return () => window.removeEventListener("resize", throttleCalcHeight)
  })
}

export default useCalcHeight
